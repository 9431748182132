//
// Floats
//

.clearfix {
  @include clearfix();
}

.center-block {
  @include center-block();
}

.pull-right {
  @include pull-right();
}

.pull-left {
  @include pull-left();
}


//
// Screenreaders
//

.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
[hidden] {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  @include text-hide();
}


//
// Text
//

// Alignment

.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }
.text-truncate       { @include text-truncate; }

// Responsive alignment

.text-xs-left   { text-align: left; }
.text-xs-right  { text-align: right; }
.text-xs-center { text-align: center; }

@include media-breakpoint-up(sm) {
  .text-sm-left   { text-align: left; }
  .text-sm-right  { text-align: right; }
  .text-sm-center { text-align: center; }
}

@include media-breakpoint-up(md) {
  .text-md-left   { text-align: left; }
  .text-md-right  { text-align: right; }
  .text-md-center { text-align: center; }
}

@include media-breakpoint-up(lg) {
  .text-lg-left   { text-align: left; }
  .text-lg-right  { text-align: right; }
  .text-lg-center { text-align: center; }
}

@include media-breakpoint-up(xl) {
  .text-xl-left   { text-align: left; }
  .text-xl-right  { text-align: right; }
  .text-xl-center { text-align: center; }
}

// Transformation

.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

// Contextual colors

.text-muted {
  color: $text-muted;
}

@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $brand-success);

@include text-emphasis-variant('.text-info', $brand-info);

@include text-emphasis-variant('.text-warning', $brand-warning);

@include text-emphasis-variant('.text-danger', $brand-danger);


// Contextual backgrounds
// For now we'll leave these alongside the text classes until v4 when we can
// safely shift things around (per SemVer rules).

// Inverse
// Todo: redo this as a proper class
.bg-inverse {
  color: $gray-lighter;
  background-color: $gray-dark;
}

.bg-faded {
  background-color: $gray-lightest;
}

@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-success', $brand-success);

@include bg-variant('.bg-info', $brand-info);

@include bg-variant('.bg-warning', $brand-warning);

@include bg-variant('.bg-danger', $brand-danger);
