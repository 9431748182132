// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.label {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: $label-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius();

  // Empty labels collapse automatically
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Add hover effects, but only for links
a.label {
  @include hover-focus {
    color: $label-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }
}

// Pill labels
//
// Make them extra rounded with a modifier to replace v3's badges.

.label-pill {
  padding-left: .6em;
  padding-right: .6em;
  border-radius: 1rem;
}


// Colors
//
// Contextual variations (linked labels get darker on :hover).

.label-default {
  @include label-variant($label-default-bg);
}

.label-primary {
  @include label-variant($label-primary-bg);
}

.label-success {
  @include label-variant($label-success-bg);
}

.label-info {
  @include label-variant($label-info-bg);
}

.label-warning {
  @include label-variant($label-warning-bg);
}

.label-danger {
  @include label-variant($label-danger-bg);
}
