.app-navbar {
  margin: 0 20px;
  background: transparent;
  font-weight: 500;
  letter-spacing: .05em;
  position: absolute;
  a {
    color: white;
  }
}

.navbar-brand img {
  max-height: 5.53rem;
}

.navbar-nav .nav-item + .nav-item {
  margin-left: 1.5rem;
}

.navbar-nav .nav-link {
  margin-top: 1.525rem; //1.825rem;
  margin-bottom: 1.525rem; //1.825rem;
}