.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: $spacer-y;
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($border-radius);

  > li {
    display: inline-block;

    + li:before {
      $nbsp: "\00a0";
      padding-right: .5rem;
      padding-left: .5rem;
      color: $breadcrumb-divider-color;
      content: "#{$breadcrumb-divider}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
