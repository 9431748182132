section.hero {
  @include gradient-vertical($brand-primary, $brand-secondary);
  position: relative;
  * {
    z-index: 3;
  }
  .graph {
    background: url(/images/grid.svg) center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;
    text-align: center;
    margin: auto;
  }
  .center {
    margin: auto;
    position: absolute;
    left: 0; top: 0; right: 0; bottom: 0;
    height: 260px;
    @include fadeIn();
  }
  img {
    width: 320px;
    margin: 0 auto;
    display: block;
    position: relative;
    @include  fadeInDown();
  }
  p {
    text-align: center;
    color: white;
  }
  ul.contact-info {
    list-style: none;
    padding: 0;
    font-style: italic;
  }
  a.button {
    background: white;
    border-radius: 3px;
    display: inline-block;
    padding: 4px 10px;
    color: $brand-primary;
    text-decoration: none;
    font-weight: bold;
  }
  a {
    text-decoration: none;
    color: white;
  }
  i.fa {
    color: white;
    font-size: .95em;
    margin-right: 5px;
  }
  h2 {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: bold;
    font-style: italic;
  }
}