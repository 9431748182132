@import "../../../vendor/twbs/bootstrap/scss/bootstrap";
@import "../../../bower_components/animatewithsass/animate.scss";
@import "../../../vendor/fortawesome/font-awesome/scss/font-awesome.scss";

@import "_variables.scss";
@import "_navigation.scss";
// Pages
@import "_hero.scss";
@import "_about.scss";
@import "_services.scss";
@import "_portfolio.scss";
@import "_contact.scss";


section.section {
  padding-top: 50px;
  min-height: 100vh;
}
::selection {
  background: $brand-secondary;
  text-shadow: none;
}
::-moz-selection {
  background: $brand-secondary;
  text-shadow: none;
}