// Contextual backgrounds

// [converter] $parent hack
@mixin bg-variant($parent, $color) {
  #{$parent} {
    color: #fff;
    background-color: $color;
  }
  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}
